<template>
  <v-card
    style="padding: 20px;"
  >
    <small>Activity Barcel� 01 OCULTAR men�:</small>
    <v-row v-if="allTasks">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        v-for="item of allTasks"
        :key="item.id"
      >
        
        <v-checkbox
          v-model="editedItem"
          :label="item.name"
          :value="item.id"
          multiple
          hide-details
          @change="handleChange"
        />
      </v-col>
    </v-row>
    <small style="color: #CCCCCC;">
      [NOTA] requiere cerrar sesi&oacute;n
    </small>
  </v-card>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    editedItem: [],
    allTasks: null,
  }),
  watch: {
    value () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
    this.handleGetAllTask()
  },
  methods: {
    prepareData () {
      if (this.value !== JSON.stringify(this.editedItem))
        this.editedItem = this.value ? JSON.parse(this.value) : []
    },
    handleGetAllTask () {
      this.allTasks = [
        {
          id: 'we',
          name: 'we'
        },
        {
          id: 'live-stage',
          name: 'live stage'
        },
        {
          id: 'barcy-club',
          name: 'barcy-club'
        },
        {
          id: 'one',
          name: 'one'
        },
        {
          id: 'pro',
          name: 'pro'
        },
      ]
    },
    handleChange () {
      this.onChange(JSON.stringify(this.editedItem))
    },
  },
}
</script>
